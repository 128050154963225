* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff;
  font-family: monospace;
}

.example::-webkit-scrollbar {
  display: none;
}

.disable-hover:hover,
.disable-hover[data-hover] {
  background: none !important;
}

/* 


*/

.container {
  position: absolute;
  top: 10%;
  left: 23%;
  width: 50%;
}

.text {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: rgb(12, 12, 188);
  cursor: pointer;
  margin-left: 10px;
  background: #fff;
  padding: 2px;
  border-radius: 4px;
}
/* 
input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
} */

.attachments {
  color: blue;
  width: rgb(19, 19, 108) x;
  display: inline list-item;
}
.input__search {
  background-color: rgb(134, 134, 213);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.element::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.element {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
